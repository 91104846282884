.dateRange .title {
  font-size: 16px;
  font-weight: 500;
  color: var(--grey-900);
}

.dateRange__content {
  margin-top: 10px;
  display: flex;
}

.dateRange__item {
  margin: 0 !important;
  max-height: 54px;
}

.dateRange__devider {
  display: block;
  margin: 21px 10px 0;
  height: max-content;
}

.dateRange__content__controls {
  margin-left: 20px;
  flex-grow: 1;
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 10px;
  }

  button {
    height: 54px;
  }
}

@media screen and (max-width: 768px) {
  .dateRange__content {
    display: grid;
    gap: 20px;
  }

  .dateRange__item {
    margin-right: 0 !important;
    height: auto !important;
  }

  .dateRange__devider {
    display: none;
  }

  .dateRange__content__controls {
    display: grid;
    gap: 10px;
    margin-left: 0;

    *:not(:first-child) {
      margin-left: 0px;
    }

    button {
      height: auto;
    }
  }
}
