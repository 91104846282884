.errorBoundary__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  margin: auto;
  max-width: 500px;
  padding: 0 10px;

  p {
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.02em;
  }

  span {
    margin-top: 8px;
    display: block;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
  }

  a {
    margin-top: 24px;
  }
}
