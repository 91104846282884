@import "Style/style.scss";

:root {
  --black: hsl(0, 0%, 0%);
  --white: hsl(0, 0%, 100%);
  --primary: hsl(231, 48%, 48%);
  --secondary: hsl(291, 64%, 42%);
  --grey-50: hsl(0, 0%, 98%);
  --grey-100: hsl(0, 0%, 96%);
  --grey-200: hsl(0, 0%, 93%);
  --grey-300: hsl(0, 0%, 88%);
  --grey-400: hsl(0, 0%, 74%);
  --grey-600: hsl(0, 0%, 46%);
  --grey-700: hsl(0, 0%, 38%);
  --grey-500: hsl(0, 0%, 62%);
  --grey-750: hsl(0, 0%, 32%);
  --grey-800: hsl(0, 0%, 26%);
  --grey-900: hsl(0, 0%, 13%);
  --brown-900: hsl(9, 28%, 19%);
  --brown-800: hsl(11, 26%, 24%);
  --brown-750: hsl(14, 26%, 29%);
  --brown-700: hsl(15, 25%, 34%);
  --brown-600: hsl(16, 25%, 38%);
  --brown-500: hsl(16, 25%, 38%);
  --brown-400: hsl(16, 18%, 47%);
  --brown-300: hsl(16, 15%, 56%);
  --brown-200: hsl(15, 15%, 69%);
  --brown-100: hsl(16, 16%, 81%);
  --brown-50: hsl(20, 16%, 93%);
  --blue-900: hsl(216, 85%, 34%);
  --blue-800: hsl(216, 85%, 34%);
  --blue-750: hsl(212, 80%, 42%);
  --blue-700: hsl(210, 79%, 46%);
  --blue-600: hsl(208, 79%, 51%);
  --blue-500: hsl(207, 90%, 54%);
  --blue-400: hsl(207, 90%, 61%);
  --blue-300: hsl(207, 89%, 68%);
  --blue-200: hsl(207, 90%, 77%);
  --blue-100: hsl(207, 89%, 86%);
  --blue-50: hsl(205, 87%, 94%);
  --teal-900: hsl(170, 100%, 15%);
  --teal-800: hsl(173, 100%, 21%);
  --teal-700: hsl(173, 100%, 24%);
  --teal-600: hsl(174, 100%, 27%);
  --teal-500: hsl(174, 100%, 29%);
  --teal-400: hsl(174, 63%, 40%);
  --teal-300: hsl(174, 42%, 51%);
  --teal-200: hsl(174, 42%, 65%);
  --teal-100: hsl(175, 41%, 79%);
  --teal-50: hsl(177, 41%, 91%);
  --green-900: hsl(124, 55%, 24%);
  --green-800: hsl(123, 46%, 34%);
  --green-700: hsl(123, 43%, 39%);
  --green-600: hsl(123, 41%, 45%);
  --green-500: hsl(122, 39%, 49%);
  --green-400: hsl(123, 38%, 57%);
  --green-350: hsl(122, 40%, 63%);
  --green-300: hsl(123, 38%, 64%);
  --green-200: hsl(122, 37%, 74%);
  --green-100: hsl(122, 38%, 84%);
  --green-50: hsl(125, 39%, 94%);
  --red-900: hsl(0, 73%, 41%);
  --red-800: hsl(0, 66%, 47%);
  --red-700: hsl(0, 65%, 51%);
  --red-600: hsl(1, 77%, 55%);
  --red-500: hsl(4, 90%, 58%);
  --red-400: hsl(1, 83%, 63%);
  --red-350: hsl(4, 90%, 72%);
  --red-300: hsl(0, 69%, 67%);
  --red-200: hsl(0, 73%, 77%);
  --red-100: hsl(354, 100%, 90%);
  --red-50: hsl(351, 100%, 96%);
  --orange-600: hsl(33, 100%, 49%);
  --orange-500: hsl(36, 100%, 50%);
  --orange-350: hsl(36, 100%, 64%);
  --orange-300: hsl(36, 100%, 65%);
  --purple-500: hsl(291, 64%, 42%);
  --purple-800: hsl(277, 70%, 35%);
  --purple-900: hsl(267, 75%, 31%);
}

* {
  outline: none;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  font-weight: 400;
  line-height: 1.4;
  font-family: Roboto, sans-serif;
  letter-spacing: 0;
  font-size: 14px;
}

body {
  position: relative;
}

#root {
  height: 100%;
}

p,
ul,
li,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

a {
  transition: 0.3s;
  text-decoration: none;
}

button {
  cursor: pointer;
  background-color: var(--white);
  transition: 0.3s;
  border: none;
  padding: 0;
}

input[type="checkbox"] {
  cursor: pointer;
}

label {
  cursor: pointer;
}

hr {
  border: none;
  min-height: 1px;
}

.app__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
