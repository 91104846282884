.categorySubPages {
  display: flex;
  flex-wrap: wrap;
}

.categorySubPage {
  margin: 15px;
  padding: 0.5em;
  background: #efefef;
  width: calc(50% - 30px);

  &__link {
    display: block;
    margin-top: 10px;
    color: #777;
    font-size: 0.9em;
  }

  &__edit-btn {
    display: inline-block;
    margin-left: 15px;
    font-style: italic;
    text-decoration: underline;
    color: #333;

    &:hover {
      color: #000;
    }
  }

  &__info {
    margin-top: 10px;
    color: #666;
    font-size: 0.85em;
  }
}
