.snackbarEvent {
  div {
    display: flex;
    align-items: center;

    p {
      color: var(--white);
      margin-left: 12px;
      font-size: 14px;
    }
  }
}

.snackbarEvent_err {
  div[role="alert"] {
    background-color: var(--red-600) !important;
  }
}

.snackbarEvent_warn {
  div[role="alert"] {
    background-color: var(--orange-500) !important;
  }
}

.snackbarEvent_info {
  div[role="alert"] {
    background-color: var(--blue-600) !important;
  }
}

.snackbarEvent_success {
  div[role="alert"] {
    background-color: var(--green-600) !important;
  }
}
