/* roboto-300 - latin-ext_cyrillic-ext */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-300.woff") format("woff"),
    /* Modern Browsers */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-300.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-300.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-regular - latin-ext_cyrillic-ext */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-regular.woff") format("woff"),
    /* Modern Browsers */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-500 - latin-ext_cyrillic-ext */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-500.eot"); /* IE9 Compat Modes */
  src: local(""), url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-500.woff") format("woff"),
    /* Modern Browsers */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-500.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-700 - latin-ext_cyrillic-ext */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-700.woff") format("woff"),
    /* Modern Browsers */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("Media/fonts/roboto-v20-latin-ext_cyrillic-ext-700.svg#Roboto") format("svg"); /* Legacy iOS */
}
